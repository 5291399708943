import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import loadable from '@loadable/component';
import SkeletonLandingView from 'views/landing/skeleton';

import { hasSubscription } from 'state/selectors/has-subscription';
import { isAuthenticated } from 'services/authentication-service';

import {
  LOGIN_ROUTE, LANDING_PAGE,
  REGISTRATION_START, SUBSCRIPTION_ROUTE,
  ACCOUNT_ROUTE, CONTACT_US,
  ALL_CARDS_ROUTE, SUPPORT_HOME,
} from 'constants/navigation';
import {
  LOGIN_ICON, HOME_NAV,
  REGISTER_ICON, SUBSCRIPTION_NAV,
  ACCOUNT_NAV, EMAIL_NAV,
  LOYALTY_CARD_NAV, SUPPORT_NAV,
} from 'constants/font-awesome';

const LazyLanding = loadable(() => import('suspended-page/landing'));

const loggedOutNav = [
  { route: LANDING_PAGE, icon: HOME_NAV, message: 'Home' },
  { route: CONTACT_US, icon: EMAIL_NAV, message: 'Contact' },
  { route: LOGIN_ROUTE, icon: LOGIN_ICON, message: 'Login' },
  { route: REGISTRATION_START, icon: REGISTER_ICON, message: 'Register' },
];

const loggedInHasSubNav = [
  { route: LANDING_PAGE, icon: HOME_NAV, message: 'Home' },
  { route: SUPPORT_HOME, icon: SUPPORT_NAV, message: 'Support' },
  { route: ACCOUNT_ROUTE, icon: ACCOUNT_NAV, message: 'Account' },
  { route: ALL_CARDS_ROUTE, icon: LOYALTY_CARD_NAV, message: 'Cards' },
];

const loggedInNoSubNav = [
  { route: LANDING_PAGE, icon: HOME_NAV, message: 'Home' },
  { route: SUPPORT_HOME, icon: SUPPORT_NAV, message: 'Support' },
  { route: SUBSCRIPTION_ROUTE, icon: SUBSCRIPTION_NAV, message: 'Subscription' },
  { route: CONTACT_US, icon: EMAIL_NAV, message: 'Contact' },
];

const LandingPage = ({ location = {} }) => {
  const { hash = '' } = location;

  const [navItems, setNavItems] = useState(loggedOutNav);

  const authenticated = isAuthenticated();

  useEffect(() => {
    const getSubNavigation = async () => {
      const sub = await hasSubscription();
      setNavItems(sub ? loggedInHasSubNav : loggedInNoSubNav);
    };

    if (authenticated) {
      getSubNavigation();
    } else {
      setNavItems(loggedOutNav);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <LazyLanding
      hash={hash}
      navItems={navItems}
      fallback={<SkeletonLandingView/>}/>
  );
};

LandingPage.propTypes = { location: object };

export default LandingPage;
