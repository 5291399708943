import React from 'react';
import { Skeleton } from '@material-ui/lab';

import useStyles from 'apputil/view-styles';

import { Paper, Grid } from '@material-ui/core';

const SkeletonLandingView = () => {
  const styles = useStyles();

  return (
    <Grid spacing={1}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={styles.wrapper}
     >
      <Grid item xs={12} sm={12} lg={10}>
        <Paper className={styles.paper} elevation={4}>
          <Skeleton animation="wave" height={125} className={styles.bodyHeader}/>
          <Skeleton animation="wave" height={50} className={styles.body2Indent}/>
          <div className={styles.body2Indent}>
            <Skeleton animation="wave" height={50} />
            <Skeleton animation="wave" height={50} />
          </div>
          <Skeleton animation="wave" height={100} className={styles.bodyHeader}/>
          <Skeleton animation="wave" height={50} className={styles.body2Indent}/>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SkeletonLandingView;
